<script>
export default {
  components: {},
};
</script>

<template>

  <div class="row">
    <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-5">
      <slot name="sidebar"></slot>
    </div>
    <div class="col-xxl-9 col-xl-9 col-lg-8 col-md-7">
      <slot name="content"></slot>
    </div>
  </div>

</template>
