<script>
import PodcastItem from "../../podcasts/PodcastItem.vue";
import Table from "../../podcast/_Table.vue";

export default {
  data() {
    return {
      menu: 'main'
    }
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
  components: {
    Table,
    PodcastItem,
  },
  methods: {
    selectAccountMenu(item) {
      this.menu = item;
      this.$emit('changedSidebarMenu', item);
    },
  }
};
</script>

<template>
  <div class="team-list grid-view-filter row">

    <div class="col-12">
      <PodcastItem v-bind:data="data.podcast" v-if="data.podcast"></PodcastItem>
    </div>

    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h5 class="mb-0">Podcast editor</h5>
        </div>
        <b-list-group class="list-group-fill-primary list-group-flush">
          <b-list-group-item :active="menu === 'main'" v-on:click="selectAccountMenu('main')" class="list-group-item-action" role="button">
            <i class="ri-profile-line align-middle me-2"></i> Podcast details
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>

    <div class="col-12">
      <Table v-bind:data="data.podcast"></Table>
    </div>

  </div>
</template>