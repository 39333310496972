<script>
export default {
  data() {
    return {
    };
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
          id: null,
          title: '',
          link: '',
          memo: '',
          urlAvatar: '',
          urlCover: ''
        }
      }
    },
    showUrl: Boolean,
  },
  components: {
  },
  computed: {
    getPodcastUrl: function() {
      try {
        return new URL(this.data.url).hostname;
      }
      catch {
        return this.data.url;
      }
    },
    getSecondsOfPodcast() {
      return parseInt(this.data.stat.seconds / 60);
    },
    getShortTitlePodcast() {
      if(this.data.short)
        return this.data.short;
      else
        return '&nbsp;';
    },
    rssFeed: function() {
      return process.env.VUE_APP_URL + 'tools/rss/podcast/' + this.data.link;
    }
  }
};
</script>

<template>
  <div class="card team-box ribbon-box ribbon-fill">
    <div class="team-cover" v-if="data.urlCover">
      <img v-bind:src="data.urlCover" alt="" class="img-fluid" />
    </div>
    <div class="card-body p-4">
      <div class="ribbon ribbon-danger fs-small" v-if="data.flags.isNew"><small>New</small></div>
      <div class="row align-items-center team-row">
        <div class="col team-settings">
          <div class="row">
            <div class="col">
            </div>
            <div class="col text-end dropdown">
              <a href="javascript:void(0);" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="ri-more-fill fs-17"></i>
              </a>
              <ul class="dropdown-menu dropdown-menu-end">
                <li>
                  <router-link :to="{ name: 'podcast', params: { name: data.link } }" class="dropdown-item">
                    <i class="ri-eye-line me-2 align-middle"></i> View
                  </router-link>
                </li>
                <li>
                  <a class="dropdown-item" :href="rssFeed" v-if="data.share === 2"><i class="ri-rss-line me-2 align-middle"></i> RSS feed</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col">
          <div class="team-profile-img">
            <div class="avatar-xl img-thumbnail rounded-circle flex-shrink-0 ">
              <router-link :to="{ name: 'podcast', params: { name: data.link } }">
                <div class="avatar-title rounded-circle text-center" v-if="!data.urlAvatar">{{ data.title }}</div>
                <img v-bind:src="data.urlAvatar" alt="" class="img-fluid d-block rounded-circle" />
              </router-link>
            </div>
            <div class="team-content">
              <router-link :to="{ name: 'podcast', params: { name: data.link } }">
                <h5 class="fs-16 mb-1">
                  {{ data.title }}
                  <i v-if="data.isVerified" class="ri-check-fill text-primary"></i>
                </h5>
              </router-link>
              <p class="text-muted mb-0" v-html="getShortTitlePodcast"></p>
              <p class="text-primary mb-0" v-if="data.url && showUrl">
                <i class="ri-link-m"></i> <a v-bind:href="data.url" target="_blank" class="text-primary">{{ getPodcastUrl }}</a>
              </p>

              <div class="avatar-group mt-4 justify-content-center">
                <div class="avatar-group-item" v-for="p in data.authors" v-bind:key="p">
                  <router-link :to="{ name: 'profile', params: { login: p.profile.login } }">
                    <div class="avatar-sm" v-if="!p.profile.urlAvatar">
                      <div class="avatar-title rounded-circle">{{ p.profile.login[0].toUpperCase() }}</div>
                    </div>
                    <img v-bind:src="p.profile.urlAvatar" :alt="p.profile.login" class="rounded-circle avatar-sm" v-if="p.profile.urlAvatar">
                  </router-link>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="col-lg-4 col">
          <div class="row text-muted text-center">
            <div class="col-4 border-end border-end-dashed">
              <h5 class="mb-1">{{ data.stat.favourites }}</h5>
              <p class="text-muted mb-0">Favs</p>
            </div>
            <div class="col-4 border-end border-end-dashed">
              <h5 class="mb-1">{{ data.stat.episodes }}</h5>
              <p class="text-muted mb-0">Episodes</p>
            </div>
            <div class="col-4">
              <h5 class="mb-1">{{ getSecondsOfPodcast }}</h5>
              <p class="text-muted mb-0">Minutes</p>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>