<script>
export default {
  components: {},
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    }
  },
};
</script>

<template>
  <div class="row pb-4">
    <div class="col-12">
      <div class="d-flex align-items-lg-center flex-lg-row flex-column">
        <div class="flex-grow-1">
          <h4 class="fs-16 mb-1">{{ title }}</h4>
          <p class="text-muted mb-0">{{ description }}</p>
        </div>
        <div class="mt-3 mt-lg-0">
          <div class="row g-3 mb-0 align-items-center">
            <div class="col-auto">
              <slot />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
