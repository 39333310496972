<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/PageHeader.vue";
import Container from "@/components/Container.vue";
import appConfig from "../../../../app.config";

import axios from 'axios';
import EpisodeSidebar from "./EpisodeSidebar.vue";
import EpisodeMenuContent from "./EpisodeMenuContent.vue";

export default {
  page: {
    title: "Podcast",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      title: "Podcast",
      menu: 'main',
      data: {
      }
    };
  },
  components: {
    Layout,
    PageHeader,
    Container,
    EpisodeSidebar,
    EpisodeMenuContent,
  },
  created: function () {
    this.loadingPodcast();
  },
  methods: {
    loadingPodcast() {
      axios.get('/api/management/podcast/edit/' + this.$route.params.name, { withCredentials: true })
        .then((response) => {
          this.data = response.data;
        })
        .catch(function () {
        });
    },
    sidebarMenuChanged(e) {
      this.menu = e;
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" v-if="false" />
    
    <Container>
      <template #sidebar>
        <EpisodeSidebar v-bind:data="data" @changed-sidebar-menu="sidebarMenuChanged"></EpisodeSidebar>
      </template>
      <template #content>
        <EpisodeMenuContent v-if="menu==='main'" v-bind:data="data" @refresh-podcast="loadingPodcast"></EpisodeMenuContent>
      </template>
    </Container>
  </Layout>
</template>
