<script>
import PodcastDetails from "./_Details.vue";

export default {
  data() {
    return {
    }
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
  components: {
    PodcastDetails
  },
  methods: {
  }
};
</script>

<template>
  <div class="card mt-xxl">
    <div class="card-header">
      <ul class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
        <li class="nav-item">
          <a class="nav-link text-body active" data-bs-toggle="tab" href="#episodeDetails" role="tab" aria-selected="true">
            Episode Details
          </a>
        </li>
      </ul>
    </div>
    <div class="card-body p-4">
      <div class="tab-content">
        <div class="tab-pane active" id="episodeDetails" role="tabpanel">
          <PodcastDetails v-bind:data="data"></PodcastDetails>
        </div>
      </div>
    </div>
  </div>
</template>